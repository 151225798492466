<template>
  <ul class="menu-nav">
    <li v-if="isPortalBlock1(1)" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal-home' }">
        <span class="menu-text text-primary"> {{ $t("PORTAL.HOME") }}</span>
      </router-link>
    </li>
    <li v-if="isPortalBlock1(2)" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal-project' }">
        <span class="menu-text text-primary"> {{ $t("PORTAL.PROJECT") }}</span>
      </router-link>
    </li>

    <li v-if="isPortalBlock1(3)" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal-instruments' }">
        <span class="menu-text text-primary">
          {{ $t("PORTAL.INSTRUMENTS") }}</span
        >
      </router-link>
    </li>
    <li v-if="isPortalBlock1(4)" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal-contact-us' }">
        <span class="menu-text text-primary">
          {{ $t("PORTAL.CONTACTUS") }}</span
        >
      </router-link>
    </li>
    <li v-if="isPortalBlock1(5)" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal-subscribe' }">
        <span class="menu-text text-primary">
          {{ $t("PORTAL.SUBSCRIBE") }}</span
        >
      </router-link>
    </li>
    <!-- <li class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'intro' }">
        <span class="menu-text text-primary"
          ><i class="fas fa-bookmark mr-3 text-primary"></i>
          {{ $t("PORTAL.DELIBERATIVESPACE") }}</span
        >
      </router-link>
    </li> -->
    <!-- choose language -->
    <li
      v-if="languages && languages.length > 1"
      class="menu-item menu-item-submenu"
    >
      <button @click="showChooseLanguafeModal" class="menu-link menu-toggle">
        <span class="menu-text"><i class="text-primary fas fa-globe"></i></span>
      </button>
      <ChooseLanguageModal
        ref="chooseLanguageModal"
        :languagesProp="languages"
      ></ChooseLanguageModal>
    </li>
    <!--end: choose language -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import ChooseLanguageModal from "@/view/layout/header/partials/ChooseLanguageModal.vue";

export default {
  name: "portal-menu",
  data() {
    return {
      organization: {},
      isGroupPublic: null,
    };
  },
  components: {
    ChooseLanguageModal,
  },
  computed: {
    ...mapGetters(["getOrganization"]),
  },
  created() {
    this.languages = this.getOrganization.data.languages;
  },
  methods: {
    showChooseLanguafeModal() {
      this.$refs["chooseLanguageModal"].open();
    },
    isPortalBlock1(portalBlock) {
      switch (portalBlock) {
        case 1:
          return this.getOrganization?.data?.portalBlock1;
        case 2:
          return this.getOrganization?.data?.portalBlock2;
        case 3:
          return this.getOrganization?.data?.portalBlock3;
        case 4:
          return this.getOrganization?.data?.portalBlock4;
        case 5:
          return this.getOrganization?.data?.portalBlock5;
        default:
          return false;
      }
    },
  },
};
</script>
