<template>
  <div
    id="kt_header"
    ref="kt_header"
    class="header header-fixed"
    v-bind:class="headerClasses"
  >
    <div class="d-none d-lg-block mt-2" v-if="url != ''">
      <a :href="url" target="_blank">
        <img
          alt="Logo"
          :src="
            getOrganization.data
              ? getOrganization.data.logo2Uri
              : '/assets/media/bipart/logo-bipart-default.svg'
          "
          width="130"
          height="35"
          class="ml-10 mt-2"
        />
      </a>
    </div>
    <div class="d-none d-lg-block mt-2" v-else-if="portal">
      <img
        alt="Logo"
        :src="
          getOrganization.data
            ? getOrganization.data.logo2Uri
            : '/assets/media/bipart/logo-bipart-default.svg'
        "
        width="130"
        height="35"
        class="ml-10 mt-2"
      />
    </div>
    <div class="d-none d-lg-block mt-2" v-else>
      <router-link to="/intro">
        <img
          alt="Logo"
          :src="
            getOrganization.data
              ? getOrganization.data.logo2Uri
              : '/assets/media/bipart/logo-bipart-default.svg'
          "
          width="130"
          height="35"
          class="ml-10 mt-2"
        />
      </router-link>
    </div>

    <div class="container-fluid d-flex align-items-center justify-content-end">
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          id="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
          ref="kt_header_menu"
          v-bind:class="headerMenuClasses"
        >
          <KTPortalMenu v-if="portal"></KTPortalMenu>
          <KTMenu v-else></KTMenu>
        </div>
      </div>
      <KTTopbar v-if="!portal"></KTTopbar>
    </div>
    <!--<button @click="showChooseLanguafeModal" class="btn btn-danger">Qui</button>-->
    <!--<ChooseLanguageModal ref="chooseLanguageModal"></ChooseLanguageModal>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import KTPortalMenu from "@/view/layout/header/PortalMenu";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu,
    KTPortalMenu,
  },
  props: {
    portal: { default: false },
  },
  data() {
    return {
      url: "",
      domain: window.location.host,
    };
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    this.url = this.getOrganization.data.url ?? "";
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "getOrganization"]),
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
