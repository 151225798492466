var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"kt_header",staticClass:"header header-fixed",class:_vm.headerClasses,attrs:{"id":"kt_header"}},[(_vm.url != '')?_c('div',{staticClass:"d-none d-lg-block mt-2"},[_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_c('img',{staticClass:"ml-10 mt-2",attrs:{"alt":"Logo","src":_vm.getOrganization.data
            ? _vm.getOrganization.data.logo2Uri
            : '/assets/media/bipart/logo-bipart-default.svg',"width":"130","height":"35"}})])]):(_vm.portal)?_c('div',{staticClass:"d-none d-lg-block mt-2"},[_c('img',{staticClass:"ml-10 mt-2",attrs:{"alt":"Logo","src":_vm.getOrganization.data
          ? _vm.getOrganization.data.logo2Uri
          : '/assets/media/bipart/logo-bipart-default.svg',"width":"130","height":"35"}})]):_c('div',{staticClass:"d-none d-lg-block mt-2"},[_c('router-link',{attrs:{"to":"/intro"}},[_c('img',{staticClass:"ml-10 mt-2",attrs:{"alt":"Logo","src":_vm.getOrganization.data
            ? _vm.getOrganization.data.logo2Uri
            : '/assets/media/bipart/logo-bipart-default.svg',"width":"130","height":"35"}})])],1),_c('div',{staticClass:"container-fluid d-flex align-items-center justify-content-end"},[_c('div',{ref:"kt_header_menu_wrapper",staticClass:"header-menu-wrapper header-menu-wrapper-left"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",class:_vm.headerMenuClasses,attrs:{"id":"kt_header_menu"}},[(_vm.portal)?_c('KTPortalMenu'):_c('KTMenu')],1)]),(!_vm.portal)?_c('KTTopbar'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }