<template>

  <div class="topbar align-items-center">
    <KTQuickUser v-if="is_not_anonymous"></KTQuickUser>
  </div>

</template>



<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
// import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import { mapGetters } from "vuex";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import { LOGOUT } from "@/core/services/store/auth.module";



export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    // KTDropdownLanguage,
    KTQuickUser,
    //KTQuickPanel
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    is_not_anonymous(){
      return (this.currentUser.authorities && !this.currentUser.authorities.includes('SCOUT'));
    }
  }
};
</script>
