<template>
  <b-modal centered id="chooseLanguageModal" ref="chooseLanguageModal" v-bind:title="$t('TRANSLATOR.SELECT')" size="" hide-footer>
    <div class="row">
      <div class="col-3 mt-3" v-for="l in languages" v-bind:key="l.locale">
        <button v-bind:class="isSelectedLanguage(l.locale)" @click="setLanguage(l.locale)">
            <div class="d-flex flex-column align-items-center">
              <img :src="l.flag.uri" style="width: 30px; height: auto"/>
              <div class="d-flex flex-column font-weight-bold mt-2">
                <!--<a class="text-dark text-hover-primary mb-1 font-size-lg"> {{ $t("LANGUAGE." + l.locale.toUpperCase()) }} </a>-->
                <a class="text-dark text-hover-primary mb-1 font-size-lg"> {{ l.name }} </a>
              </div>
            </div>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "ChooseLanguageModal",
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  mounted() {
    this.getLanguages();
  },
  props: ["languagesProp"],
  data() {
    return {
      languages: []
    }
  },
  methods: {
    isSelectedLanguage(lang) {
      return (lang==i18nService.getActiveLanguage()) ? 'btn btn-clean border w-100 border-primary' : 'btn btn-clean border w-100';
    },
    close() {
      this.$refs["chooseLanguageModal"].hide();
    },
    open() {
      this.$refs["chooseLanguageModal"].show();
    },
    getLanguages() {
      //this.languages = this.getOrganization.data.languages;
      this.languages = this.languagesProp;
    },
    setLanguage(lang) {
      i18nService.setActiveLanguage(lang);
      location.reload();
      //this.close();
    }
  },
  watch: {
    getOrganization: {
      handler() {
        this.getLanguages();
      },
      immediate: true
    }
  }
};
</script>
<style scoped>
  .error{
    color: #ff0000;
  }
  .invalid{
    border-color: #ff0000;
  }
</style>
