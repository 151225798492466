<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        {{$t("QUICKUSER.SALUTO")}}
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
        v-if="currentUser.name"
      >
        {{ currentUser.name }}
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
        v-else
      >
      {{ getUserIdentifier }}
      </span>
<!--      <span class="symbol symbol-35 symbol-light-success" style="height: 35px; width: 35px">-->
<!--        <img :src="currentUser.avatar" style="width: 100%" alt="avatar">-->
<!--      </span>-->
      <span class="symbol symbol-35 symbol-light-success" :style="$imageOptions.styleSettings({width: 35, height: 35, userImage: (currentUser.avatar ? currentUser.avatar : $imageOptions.defaultAvatarUrl)})">
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <!-- <h3 class="font-weight-bold m-0">
          User Profile
          <small class="text-muted font-size-sm ml-2">12 messages</small>
        </h3> -->
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
<!--          #originale-->
<!--         <span class="symbol symbol-100 symbol-light-success mr-5">-->
<!--            <img v-if="false" alt="Pic" :src="picture" />-->
<!--            <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">-->
<!--            </span>-->
<!--          </span>-->
<!--          #n: gianpaolo-->
          <span class="symbol symbol-100 symbol-light-success mr-5" :style="$imageOptions.styleSettings({width: 100, height: 100, userImage: (currentUser.avatar ? currentUser.avatar : $imageOptions.defaultAvatarUrl)})"></span>

          <div class="d-flex flex-column">
            <router-link
              :to="{name: 'personalSpace'}"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ getUserIdentifier }}
            </router-link>
            <div class="text-muted my-2">
              {{ authorities }}
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{$t("QUICKUSER.SIGNOUT")}}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>

       <div class="navi navi-spacer-x-0 p-0">

         <router-link
            to="/personal-space-activities"
            class="navi-item"
            >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <i class="fa fa-star icon-md"></i>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{$t("MAINMENU.PSACTIVITIES")}}</div>
                <div class="text-muted">{{$t("QUICKUSER.IDEEVOTATE")}}</div>
              </div>
            </div>
          </router-link>

          <router-link
            to="/personal-space-account"
            class="navi-item"
            >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <i class="fas fa-user-circle icon-md"></i>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{$t("MAINMENU.PSSETTINGS")}}</div>
                <div class="text-muted">{{$t("QUICKUSER.AGGIORNAPROFILOEPRIVACY")}}</div>
              </div>
            </div>
          </router-link>
          <!-- <a href="/community-space" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Community Space</div>
                <div class="text-muted">Ultime Idee, commenti, voti</div>
              </div>
            </div>
          </a> -->
        </div>

        <!-- <div class="separator separator-dashed my-7"></div>

        <div>
          <h5 class="mb-5">{{$t("QUICKUSER.PROCESSIPARTECIPATIVI")}}</h5>
          <template v-for="(item, i) in list">
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"
              v-bind:key="i"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <inline-svg :src="item.svg" />
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div>
          </template>
        </div> -->

      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
// import Vue from "vue";
import { mapGetters } from "vuex";
import {ANONYMOUS_LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import store from "@/core/services/store";
import router from "@/router";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [

      ],
      authorities: ""
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.authorities = this.currentUser.authorities.filter((x) => x == "EXPERT" || x == "ASSESSOR").map((x) => this.$t(`AUTHORITIES.${x}`)).join(" | ")
  },
  methods: {
    // async dispatchAnonymousLogin() {
    //   await store.dispatch(ANONYMOUS_LOGIN);
    //   if (Vue.prototype.$currentDomain.includes('partecipo-romagnafaentina.it')) {
    //     await router.push({name: "intro"});
    //   } else {
    //     await router.push({name: "home"});
    //   }
    // },
    async onLogout() {
      await store.dispatch(LOGOUT);
      if(!this.getOrganization.data.publiclyVisible) {
          router.push({name: "login", query: {confirmedEmail: 'true'}});
        } else {
          await store.dispatch(ANONYMOUS_LOGIN);
          router.push({name: "intro"});
        }
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getOrganization"
    ]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    getUserIdentifier() {
      if(this.currentUser.name || this.currentUser.surname) {
        return this.currentUser.name + ' ' + this.currentUser.surname;
      } else {
        return this.currentUser.username;
      }
    }
  }
};
</script>
